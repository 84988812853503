import { userInfo,login } from "@/api/login"
export default {
    state: {
        userInfo: {},
        token:""
    },
    mutations: {
        GET_USERINFO(state, data) {
            state.userInfo = data
        },
        SET_TOKEN(state,data){
            state.token = data
        }
    },
    actions: {
        getUserInfo({ commit }) {
            return new Promise(() => {
                userInfo().then(res => {
                    if (res.data.status == 200) {
                        commit('GET_USERINFO', res.data.data)
                    }
                })
            })
        },
        Login({ commit }, token) {
            return new Promise((resolve, reject) => {
                login(token)
                    .then((res) => {
                        if(res.data.status==200){
                            resolve(res.data.data)
                            commit('SET_TOKEN',res.data.data.token)
                            resolve()
                        } else{
                            reject(res.data.msg)
                        }
                       
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
    }
}