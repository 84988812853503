import enquireJs from 'enquire.js'
import Cookies from 'js-cookie'

export function isDef (v){
  return v !== undefined && v !== null
}

/**
 * Remove an item from an array.
 */
export function remove (arr, item) {
  if (arr.length) {
    const index = arr.indexOf(item)
    if (index > -1) {
      return arr.splice(index, 1)
    }
  }
}

export function isRegExp (v) {
  return _toString.call(v) === '[object RegExp]'
}

export function enquireScreen(call) {
  const handler = {
    match: function () {
      call && call(true)
    },
    unmatch: function () {
      call && call(false)
    }
  }
  enquireJs.register('only screen and (max-width: 767.99px)', handler)
}

const _toString = Object.prototype.toString


export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

// 设置setCookies；
// setToken
export const setCookies = (key, val, cookieExpires) => {
  Cookies.set(key, val, { expires: cookieExpires || 1 })
}
// 获取getCookies；
// getToken
export const getCookies = (key) => {
  return Cookies.get(key)
}

export const removeCookies = (key) => {
  return Cookies.remove(key)
}