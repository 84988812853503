
import Vue from 'vue';
const vm = new Vue;
let scoketUrl = process.env.VUE_APP_SOCKET
class wsSocket {
    constructor(opt) {
        this.ws = null;
        this.opt = opt || {};
        this.init(opt.key);
    }

    onOpen() {
        this.opt.open && this.opt.open();
        let that = this;
        that.ping();
        this.socketStatus = true;
    }

    init() {
        let wsUrl = scoketUrl
        if (wsUrl) {
            this.ws = new WebSocket(wsUrl);
            this.ws.onopen = this.onOpen.bind(this);
            this.ws.onerror = this.onError.bind(this);
            this.ws.onmessage = this.onMessage.bind(this);
            this.ws.onclose = this.onClose.bind(this);
        }

    }

    ping() {
        var that = this;
        this.timer = setInterval(function () {
            that.send({ type: 'ping' });
        }, 10000);
    }

    send(data) {
        return new Promise((resolve, reject) => {
            try {
                this.ws.send(JSON.stringify(data));
                resolve({ status: true });
            } catch (e) {
                reject({ status: false })
            }
        });
    }

    onMessage(res) {
        this.opt.message && this.opt.message(res);
    }

    onClose() {
        this.timer && clearInterval(this.timer);
        this.opt.close && this.opt.close();
    }

    onError(e) {
        this.opt.error && this.opt.error(e);
    }

    $on(...args) {
        vm.$on(...args);
    }
}


function createSocket(key) {
    return new Promise((resolve, reject) => {
        const ws = new wsSocket({
            key,
            open() {
                resolve(ws);
            },
            error(e) {
                reject(e)
            },
            message(res) {
                if (res.data) {
                    const { type, data = {} } = JSON.parse(res.data);
                    vm.$emit(type, data);
                }
              
            },
            close(e) {
                vm.$emit('close', e);
            }
        })
    });
}


export const scoket = createSocket()