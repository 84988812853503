import TabsView from "@/layouts/tabs/TabsView";
import BlankView from "@/layouts/BlankView";
// import orderView from '@/pages/bookBuy/myOrder/index'

// 路由配置
const options = {
  mode: "hash",
  routes: [
    {
      path: "/login",
      name: "登录页",
      component: () => import("@/pages/login"),
    },
    {
      path: "/forgetPwd",
      name: "忘记密码",
      component: () => import("@/pages/login/ForgetPwd"),
    },
    {
      path: "*",
      name: "404",
      component: () => import("@/pages/exception/404"),
    },
    {
      path: "/403",
      name: "403",
      component: () => import("@/pages/exception/403"),
    },
    {
      path: "/",
      redirect: "/login",
      component: TabsView,
      children: [
        {
          path: "bookBuy",
          name: "商品",
          meta: {
            icon: "home",
          },
          component: BlankView,
          children: [
            {
              path: "allGoods",
              name: "全部商品",
              meta: {
                invisible: false,
                keepAlive: true,
                title: "全部商品",
                page: {
                  closable: false,
                },
              },
              component: () => import("@/pages/bookBuy/allGoods/WorkPlace.vue"),
            },
            {
              path: "books",
              name: "图书采购",
              meta: {
                invisible: false,
                keepAlive: true,
              },
              component: () => import("@/pages/bookBuy/productType/books"),
            },
            {
              path: "booksBatch",
              name: "借阅套餐采购",
              meta: {
                invisible: false,
                keepAlive: true,
              },
              component: () => import("@/pages/bookBuy/productType/booksCover"),
            },
            {
              path: "material",
              name: "物资采购",
              meta: {
                keepAlive: true,
                invisible: false,
              },
              component: () => import("@/pages/bookBuy/productType/material"),
            },
            {
              path: "materialBatch",
              name: "物资套餐采购",
              meta: {
                invisible: false,
                keepAlive: true,
              },
              component: () =>
                import("@/pages/bookBuy/productType/materialCover"),
            },
            {
              path: "serviceCharge",
              name: "服务费采购",
              meta: {
                invisible: false,
              },
              component: () =>
                import("@/pages/bookBuy/productType/serviceCharge"),
            },
            {
              path: "mealGoods",
              name: "查询商品",
              meta: {
                invisible: true,
              },
              component: () => import("@/pages/bookBuy/allGoods/mealGoods"),
            },
            {
              path: "goodsDetail",
              name: "商品详情",
              meta: {
                invisible: true,
              },
              component: () => import("@/pages/bookBuy/allGoods/goodsDetail"),
            },
            {
              path: "buyCart",
              name: "购物车",
              meta: {
                invisible: false,
              },
              component: () => import("@/pages/bookBuy/allGoods/buyCart"),
            },
            {
              path: "submitOrder",
              name: "提交订单",
              meta: {
                invisible: true,
                page: {
                  closable: false,
                },
              },
              component: () => import("@/pages/bookBuy/myOrder/submitOrder"),
            },
            {
              path: "orderPay",
              name: "订单支付",
              meta: {
                invisible: true,
                page: {
                  closable: false,
                },
              },
              component: () => import("@/pages/bookBuy/myOrder/orderPay"),
            },
          ],
        },
        {
          path: "bookBuy/order",
          name: "订单",
          component: BlankView,
          redirect: "/login",
          meta: {
            icon: "shopping-cart",
          },
          children: [
            {
              path: "myOrder",
              name: "我的订单",
              meta: {
                invisible: false,
              },
              component: () => import("@/pages/bookBuy/myOrder/myOrder.vue"),
            },
            {
              path: "orderDetail",
              name: "订单详情",
              meta: {
                invisible: true,
              },
              component: () => import("@/pages/bookBuy/myOrder/orderDetail"),
            },
            {
              path: "refundOrder",
              name: "售后订单",
              component: () => import("@/pages/bookBuy/myOrder/refundOrder"),
            },
          ],
        },
        {
          path: "bookBuy/invoice",
          name: "发票",
          component: BlankView,
          redirect: "/login",
          meta: {
            icon: "audit",
          },
          children: [
            {
              path: "invoiceOrder",
              name: "可开票订单",
              meta: {
                invisible: false,
              },
              component: () => import("@/pages/invoice/invoiceOrder"),
            },
            {
              path: "myInvoice",
              name: "我的发票",
              meta: {
                page: {
                  closable: false,
                },
              },
              component: () => import("@/pages/invoice/myInvoice"),
            },
            {
              path: "refundOrder",
              name: "开票信息",
              meta: {
                page: {
                  closable: false,
                },
              },
              component: () => import("@/pages/invoice/invioceInfo"),
            },
          ],
        },
        {
          path: "bookBuy/collection",
          name: "商品收藏",
          component: () => import("@/pages/bookBuy/collection"),
          meta: {
            icon: "star",
          },
        },
        {
          path: "bookBuy/receivingAdress",
          name: "收货地址",
          component: () => import("@/pages/bookBuy/receivingAdress"),
          meta: {
            icon: "setting",
          },
        },
        {
          path: "bookBuy/beforeBalance",
          name: "账户余额",
          component: () => import("@/pages/beforeBalance/index"),
          meta: {
            icon: "money-collect",
          },
        },
      ],
    },
  ],
};

export default options;
