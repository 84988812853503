<template>
  <div>
    <a-dropdown>
      <div class="header-avatar" style="cursor: pointer">
        <a-avatar class="avatar" size="small" shape="circle" :src="headImg" />
        <span class="name">{{ $store.state.user.userInfo.nickname }}</span>
      </div>
      <a-menu :class="['avatar-menu']" slot="overlay">
        <a-menu-item @click="changePassword">
          <a-icon type="user" />
          <span>修改密码</span>
        </a-menu-item>
        <!--<a-menu-item>
        <a-icon type="setting" />
        <span>设置</span>
      </a-menu-item>
      <a-menu-divider /> -->
        <a-menu-item @click="logout">
          <a-icon style="margin-right: 8px" type="poweroff" />
          <span>退出登录</span>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <a-modal
      title="修改登录密码"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <p>当前绑定的手机号为{{ $store.state.user.userInfo.phone | phone }}</p>
      <a-input-search
        placeholder="请输入验证码"
        v-model="form.code"
        @search="onSearch"
        class="mb14"
      >
        <a-button slot="enterButton" type="primary" :disabled="disabled">
          {{ getCodeText }}
        </a-button>
      </a-input-search>
      <a-input
        v-model="value1"
        type="password"
        autocomplete="new-password"
        placeholder="请设置新密码"
        style="width: 100%"
        class="mb14"
      />
      <a-input
        v-model="value2"
        type="password"
        autocomplete="new-password"
        placeholder="请再次确认新密码"
        style="width: 100%"
      />
    </a-modal>
  </div>
</template>

<script>
import { smsCode, editPassword } from "@/api/login";
import { removeAuthorization } from "@/utils/request";

var timer = null;
export default {
  name: "HeaderAvatar",
  data() {
    return {
      headImg: "",
      userName: "",
      visible: false,
      confirmLoading: false,
      form: {
        code: "",
        cipher: "",
      },
      value1: "",
      value2: "",
      getCodeText: "获取验证码",
      disabled: false,
    };
  },
  filters: {
    phone(val) {
      return val && val.substring(0, 3) + "****" + val.substr(val.length - 4);
    },
  },
  created() {
    this.$store.dispatch("getUserInfo");
  },
  mounted() {
    this.headImg = localStorage.getItem("headImg");
  },
  methods: {
    onSearch() {
      smsCode().then(() => {
        this.disabled = true;
        this.countDown();
      });
    },
    countDown() {
      let time = 60;
      timer = setInterval(() => {
        time--;
        this.getCodeText = `${time}s`;
        if (time == 0) {
          this.disabled = false;
          this.getCodeText = `获取验证码`;
          clearInterval(timer);
        }
      }, 1000);
    },
    handleOk() {
      if (this.value1 !== this.value2) {
        this.$message.warning("两次密码输入不一致");
        return;
      }
      if (!this.form.code) {
        this.$message.warning("请输入验证码");
        return;
      }
      let form = Object.assign({}, this.form);
      form.cipher = this.value1;
      this.confirmLoading = true;
      editPassword(form)
        .then(() => {
          this.$message.success("修改成功");
          this.visible = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    destroyed() {
      clearInterval(timer);
    },
    handleCancel() {
      this.visible = false;
    },
    changePassword() {
      this.visible = true;
    },
    logout() {
      localStorage.clear();
      removeAuthorization();
      this.$router.replace("/login");
    },
  },
};
</script>

<style lang="less">
.header-avatar {
  display: inline-flex;

  .avatar,
  .name {
    align-self: center;
  }

  .avatar {
    margin-right: 8px;
  }

  .name {
    font-weight: 500;
  }
}

.avatar-menu {
  width: 150px;
}

.mb14 {
  margin-bottom: 14px !important;
}
</style>
