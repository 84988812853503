// 自定义配置，参考 ./default/setting.config.js，需要自定义的属性在这里配置即可
module.exports = {
  theme: {
    color: "#3ab887",
    mode: "light",
  },
  multiPage: false,
  animate: {
    name: "lightSpeed",
    direction: "left",
  },
};
