<template>
  <!-- <page-toggle-transition :disabled="animate.disabled" :animate="animate.name" :direction="animate.direction"> -->
  <div>
    <keep-alive :include="list">
      <router-view
        v-if="$route.meta.keepAlive"
        style="min-height: 600px"
        :key="key"
      >
      </router-view>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive" :key="key" />
  </div>
  <!-- </page-toggle-transition> -->
</template>

<script>
// import PageToggleTransition from '../components/transition/PageToggleTransition';
import { mapState } from "vuex";

export default {
  name: "BlankView",
  // components: { PageToggleTransition },
  computed: {
    ...mapState("setting", ["multiPage", "animate"]),
    key() {
      return this.$route.path;
    },
  },
  data() {
    return {
      list: ["AllGoods"],
    };
  },
  created() {},
};
</script>

<style scoped></style>
