import {getRequest,postRequest,putRequest} from '@/utils/request'

//购物车列表
export const cartList = (params) => {
    return postRequest('cart/list', params)
}
// 购物车 添加
export const addCart = (params) => {
    return postRequest('cart/add', params)
}
// 购物车 删除
export const delCart = (params) => {
    return postRequest('cart/del', params)
}
// 购物车 获取数量
export const cartCount = (params) => {
    return getRequest('cart/count', params)
}
// 购物车 修改产品数量
export const cartNum = (params) => {
    return postRequest('cart/num', params)
}
// 产品分类
export const category = (params) => {
    return putRequest('store/get/category', params)
}
// 首页banner
export const advList = (params) => {
    return getRequest('other/adv/list', params)
}
// 商品列表
export const productList = (params) => {
    return putRequest('store/product/list', params)
}
// 套餐推荐商品
export const recommendList = (data,params) => {
    return getRequest('store/get_recommend/list?good_type=' + data, params)
}

// 商品详情
export const detail = (params) => {
    return getRequest('store/product/detail?product_id=' + params)
}
// 套餐推荐商品
export const productMeal = (params) => {
    return putRequest('store/get/product_meal', params)
}
// 商品详情-单品商品列表
export const singleProductList = (id,params) => {
    return getRequest('store/singleProductList/'+id, params)
}
// 商品详情-分箱商品列表
export const binningProductList = (id,params) => {
    return getRequest('store/binningProductList/'+id, params)
}
// 热卖排行
export const hotRanking = (params) => {
    return getRequest('store/hotRanking',params)
}
// 公告列表
export const articleList = (params) => {
    return getRequest('article/list',params)
}
// 公告详情
export const articleDetails = (params) => {
    return getRequest('article/details/'+params)
}