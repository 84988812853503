<template>
  <a-config-provider :locale="locale" :get-popup-container="popContainer">
    <router-view :key="new Date().getTime()" />
  </a-config-provider>
</template>

<script>
import { enquireScreen } from './utils/util'
import { mapState, mapMutations } from 'vuex'
import themeUtil from '@/utils/themeUtil';
import { getI18nKey } from '@/utils/routerUtil'

export default {
  name: 'App',
  components: {  },
  data() {
    return {
      locale: {}
    }
  },
  created() {
    this.setHtmlTitle()
    this.setLanguage(this.lang)
    enquireScreen(isMobile => this.setDevice(isMobile))
  },
  watch: {
    weekMode(val) {
      this.setWeekModeTheme(val)
    },
    lang(val) {
      this.setLanguage(val)
      this.setHtmlTitle()
    },
    $route() {
      this.setHtmlTitle()
    },
    'theme.mode': function (val) {
      let closeMessage = this.$message.loading(`您选择了主题模式 ${val}, 正在切换...`)
      themeUtil.changeThemeColor(this.theme.color, val).then(closeMessage)
    },
    'theme.color': function (val) {
      let closeMessage = this.$message.loading(`您选择了主题色 ${val}, 正在切换...`)
      themeUtil.changeThemeColor(val, this.theme.mode).then(closeMessage)
    },
    'layout': function () {
      window.dispatchEvent(new Event('resize'))
    },
  },

  computed: {
    ...mapState('setting', ['layout', 'theme', 'weekMode', 'lang']),
    theme() {
      return {
        '--theme': this.$store.state.setting.theme.color
      }
    }
  },
  mounted() {
    this.setWeekModeTheme(this.weekMode)
  },
  methods: {
    ...mapMutations('setting', ['setDevice']),
    setWeekModeTheme(weekMode) {
      if (weekMode) {
        document.body.classList.add('week-mode')
      } else {
        document.body.classList.remove('week-mode')
      }
    },
    setLanguage(lang) {
      this.$i18n.locale = lang
      switch (lang) {
        case 'CN':
          this.locale = require('ant-design-vue/es/locale-provider/zh_CN').default
          break
        case 'HK':
          this.locale = require('ant-design-vue/es/locale-provider/zh_TW').default
          break
        case 'US':
        default:
          this.locale = require('ant-design-vue/es/locale-provider/en_US').default
          break
      }
    },
    setHtmlTitle() {
      const route = this.$route
      const key = route.path === '/' ? 'home.name' : getI18nKey(route.matched[route.matched.length - 1].path)
      document.title = localStorage.getItem('systemName') + ' | ' + this.$t(key)
    },
    popContainer() {
      return document.getElementById("popContainer")
    }
  }
}
</script>

<style lang="less">
.marginL8 {
  margin-left: 8px !important;
}

.origin {
  color: #ff8d1a !important;
}

.float_r {
  float: right !important;
}

.gray {
  color: #999999 !important;
}

.font16 {
  font-size: 16px !important;
}

.font12 {
  font-size: 12px !important;
}

.font14 {
  font-size: 14px !important;
}

.red {
  color: #ff5733 !important;
}

.green {
  color: @primary-color !important;
}

.backgroundThem {
  background-color: @primary-color !important;
}

.mart5 {
  margin-top: 5px;
}

.mart10 {
  margin-top: 10px;
}

.mart8 {
  margin-top: 8px;
}

.mar22 {
  margin-bottom: 22px;
}

.text_r {
  text-align: right !important;
}

.bold {
  font-weight: bold !important;
}</style>
