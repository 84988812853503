<template>
    <div>
        <div class="fixed" v-if="open">
            <div class="header">
                <span>系统提示</span>
                <a-icon type="close" @click="close" style="font-size: 18px;cursor: pointer;" />
            </div>
            <div class="title">您有新的<span style="color: red;">{{ list[isKaipiao] }}</span>信息，请注意查收</div>
            <!-- <div class="order_id">{{ content }}</div> -->
            <div class="btns">
                <a-button type="success" style="width: 86px;" @click="close">忽略</a-button>
                <a-button type="primary" style="width: 86px;" @click="$router.push({
                    path: path
                })">查看</a-button>
            </div>
        </div>
    </div>
</template>

<script>
import { scoket } from "@/utils/socket";

export default {
    name: 'notificationBox',
    data() {
        return {
            open: false,
            content: "无",
            timer: null,
            text: "",
            isFinish: false,
            path: "",
            isKaipiao: 1,
            list: ['售后', '充值', '开票']
        }
    },
    watch: {
        open(val) {
            if (val) {
                let num = 10
                this.timer = setInterval(() => {
                    num--
                    if (num == 0) {
                        this.open = false
                        clearInterval(this.timer)
                    }
                }, 1000)
            }
        }
    },
    mounted() {
        scoket.then((ws) => {
            if (localStorage.getItem('uid')) {
                ws.send({ type: "binduid", uid: localStorage.getItem('uid') })
            }

            //开票
            ws.$on('invoice_succeed', () => {
                this.setContent(2,'/bookBuy/invoice/invoiceOrder')
            })
            ws.$on('invoice_fail', () => {
                this.setContent(2,'/bookBuy/invoice/invoiceOrder')
            })
            ws.$on('invoice_auth_succeed', () => {
                this.setContent(2,'/bookBuy/invoice/myInvoice')
            })
            ws.$on('invoice_auth_fail', () => {
                this.setContent(2,'/bookBuy/invoice/invoiceOrder')
            })
            ws.$on('invoice_start', () => {
                this.setContent(2,'/bookBuy/invoice/invoiceOrder')
            })

            //充值
            ws.$on('recharge_fail', () => {
                this.setContent(1,'/bookBuy/beforeBalance')
            })
            ws.$on('recharge_succeed', () => {
                this.setContent(1,'/bookBuy/beforeBalance')
            })

            //售后
            ws.$on('return_user_reject_warn', () => {
                this.setContent(0,'/bookBuy/order/refundOrder')
            })
            ws.$on('return_user_fulfill_warn', () => {
                this.setContent(0,'/bookBuy/order/refundOrder')
            })
        })
    },
    methods: {
        handleErr(err){
            console.log(err);
        },
        setContent(type, path) {
            this.open = true
            this.isKaipiao = type
            this.path = path
        },
        close() {
            this.open = false
        }
    },
}

</script>
<style scoped>
.fixed {
    position: fixed;
    right: 40px;
    bottom: 40px;
    z-index: 9999;
    width: 320px;
    height: 181px;
    background-color: #fff;
    box-shadow: 4px 5px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 18px 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}

.title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.order_id {
    font-size: 14px;
    font-weight: bold;
    color: rgba(58, 184, 135, 1);
    text-align: center;
}

.btns {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
</style>