
import { cartList,cartCount } from "@/api/allGoods";

export default {
    namespaced: true,
    state: {
        classNum: 0,  //购物车品类数量总数
        data: [], //购物车商品列表
        loading: false,
        plainOptions:[],
        showTotal:0,
        total:0
    },
    mutations: {
        GET_LIST(state, data) {
            state.data = data
        },
        SET_LOADING(state, data) {
            state.loading = !!data
        },
        SET_PLAIN(state, data) {
            state.plainOptions = data
        },
        SET_TOTAL(state, data) {
            state.total = data
        },
        SET_SHOWTOTAL(state, data) {
            state.showTotal = data
        },
    },
    actions: {
        getList({ commit }, form) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', 1)
                cartList(form).then((res) => {
                    commit('GET_LIST', [])
                    commit('SET_PLAIN', [])
                    commit('SET_LOADING', 0)
                    if (res.data.status == 200) {
                        let data  = res.data.data.list.productInfo && res.data.data.list.productInfo.length != 0 ? res.data.data.list.productInfo : []
                        commit('GET_LIST',data)
                        let list = []
                        data.map((item) => {
                            list.push(item.cart_id)
                        });
                        commit('SET_PLAIN', list)

                        resolve(res)
                    } else {
                        reject(res.data.msg)
                    }
                });
            })
        },
        getNum({ commit }) {
            return new Promise((resolve, reject) => {
                cartCount().then((res) => {
                    if (res.data.status == 200) {
                        commit('SET_TOTAL',  res.data.data.count)
                        commit('SET_SHOWTOTAL', res.data.data.productCount)
                        resolve()
                    }else{
                        reject(res.data.msg)
                    }
                });
            })
            
        },
    }
}